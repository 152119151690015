<template>
    <div>
        <Breadcrumb></Breadcrumb>
        <div class="header">
            <el-row>
                <div class="form">
                    <el-form :inline="true" :model="searchForm" class="demo-form-inline">
                        <el-form-item label="主题">
                            <el-input v-model="searchForm.title" placeholder="主题" size="small"></el-input>
                        </el-form-item>
                        <el-form-item label="用户">
                            <el-select v-model="searchForm.user_id" filterable clearable placeholder="用户" size="small">
                                <el-option v-for="user in checkUser" :key="user.id" :label="user.user_name" :value="user.id"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="审核状态">
                            <el-select v-model="searchForm.status" clearable placeholder="审核状态" size="small">
                                <el-option label="待审核" value="1"></el-option>
                                <el-option label="已发布" value="2"></el-option>
                                <el-option label="已驳回" value="3"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" size="small" icon="el-icon-search" @click="getTrendsList">查询</el-button>
                        </el-form-item>
                    </el-form>
                </div>
            </el-row>
            <el-row>
                <el-col :span="22">
                    <el-button type="danger" size="small" @click="delTrends()">批量删除</el-button>
                    <el-button type="success" size="small" @click="approveTrends('', 2)">批量通过</el-button>
                    <el-button type="danger" size="small" @click="approveTrends('', 3)">批量驳回</el-button>
                    <el-button type="warning" size="small" @click="approveTrends('', 1)">批量重审</el-button>
                </el-col>
                <el-col :span="2"></el-col>
            </el-row>
        </div>
        <div class="content">
            <el-table ref="multipleTable" :data="list" tooltip-effect="dark" style="width: 100%" @selection-change="handleSelectionChange" :default-sort="{ prop: 'id', order: 'ascending' }">
                <el-table-column type="selection" width="55"></el-table-column>
                <el-table-column prop="title" label="主题"></el-table-column>
                <el-table-column prop="content" label="内容"></el-table-column>
                <el-table-column label="图片">
                    <template slot-scope="scope">
                        <div class="img-container"><img v-for="img in scope.row.img" :src="img" alt="图片" :width="50" :height="50" /></div>
                    </template>
                </el-table-column>
                <el-table-column prop="user_id" label="用户" :formatter="userIdFormatter"></el-table-column>
                <el-table-column prop="status" label="状态" :formatter="statusFormatter"></el-table-column>
                <el-table-column prop="create_time" label="提交时间" sortable></el-table-column>
                <el-table-column label="操作" width="260">
                    <template slot-scope="scope">
                        <el-button v-if="scope.row.status == 1" type="success" size="mini" @click="approveTrends(scope.row.id, 2)">通过</el-button>
                        <el-button v-if="scope.row.status == 1" type="danger" size="mini" @click="approveTrends(scope.row.id, 3)">驳回</el-button>
                        <el-button v-if="scope.row.status == 2 || scope.row.status == 3" type="warning" size="mini" @click="approveTrends(scope.row.id, 1)">重审</el-button>
                        <el-button v-if="scope.row.status != 2" type="danger" size="mini" @click="delTrends(scope.row.id)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="pagination">
                <Pagination :total="total" :perPage="perPage" :currentPage="currentPage" @currentPageChange="currentPageChange"></Pagination>
            </div>
        </div>
    </div>
</template>

<script>
import { getTrendsList, approveTrends, delTrends } from '@/utils/servers/trends.js';
import { getCheckUser } from '@/utils/servers/user.js';
import Pagination from '@/components/Pagination/Pagination.vue';
export default {
    components: {
        Pagination
    },
    computed: {
        userIdFormatter() {
            return (row, column, value) => {
                let arr = this.checkUser.find(val => val.id === value);
                if (arr) {
                    return arr.user_name;
                } else {
                    return value;
                }
            };
        },
        statusFormatter() {
            return (row, column, value) => {
                let arr = [
                    { key: 1, name: '待审核' },
                    { key: 2, name: '已发布' },
                    { key: 3, name: '已驳回' }
                ];
                let item = arr.find(item => item.key === value);
                if (item) {
                    return item.name;
                } else {
                    return value;
                }
            };
        }
    },
    data() {
        return {
            searchForm: {
                title: '',
                user_id: '',
                status: ''
            },
            list: [],
            total: 0,
            perPage: 0,
            currentPage: 0,
            checkIds: [],
            checkUser: []
        };
    },
    created() {
        this.getCheckUser();
        this.getTrendsList();
    },
    methods: {
        handleSelectionChange(val) {
            this.checkIds = val ? val.map(item => item.id) : [];
        },
        currentPageChange(val) {
            this.currentPage = val;
            this.getTrendsList();
        },
        getTrendsList() {
            getTrendsList({ searchForm: this.searchForm, perPage: this.perPage, currentPage: this.currentPage }).then(res => {
                var data = res;
                if (data.code == 1) {
                    this.list = data.data.data;
                    this.total = data.data.total;
                    this.perPage = data.data.per_page;
                    this.currentPage = data.data.current_page;
                } else {
                    this.$message.error(data.msg);
                }
            });
        },
        getCheckUser() {
            getCheckUser().then(res => {
                var data = res;
                if (data.code == 1) {
                    this.checkUser = data.data.list;
                } else {
                    this.$message.error(data.msg);
                }
            });
        },
        approveTrends(id, status) {
            var ids = id ? id : this.checkIds.toString();
            this.$confirm('此操作将改变审核状态，是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                approveTrends({ ids: ids, status: status }).then(res => {
                    var data = res;
                    if (data.code == 1) {
                        this.$message.success(data.msg);

                        if (this.total % this.perPage === 1) {
                            this.currentPage = this.currentPage - 1 > 0 ? this.currentPage - 1 : 1;
                        }

                        this.getTrendsList();
                    } else {
                        this.$message.error(data.msg);
                    }
                });
            });
        },
        delTrends(id) {
            var ids = id ? id : this.checkIds.toString();
            this.$confirm('此操作将永久删除数据，是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                delTrends({ ids: ids }).then(res => {
                    var data = res;
                    if (data.code == 1) {
                        this.$message.success(data.msg);

                        if (this.total % this.perPage === 1) {
                            this.currentPage = this.currentPage - 1 > 0 ? this.currentPage - 1 : 1;
                        }

                        this.getTrendsList();
                    } else {
                        this.$message.error(data.msg);
                    }
                });
            });
        }
    }
};
</script>

<style lang="less" scoped>
.header {
    background: #fff;
    margin-bottom: 20px;
}
.content {
    .pagination {
        padding: 10px;
    }
    .img-container {
        display: flex;
        gap: 10px;
    }
}
</style>
