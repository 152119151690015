import request from '@/utils/request';

export function login(data) {
    return request({
        url: '/login/login',
        method: 'post',
        data: data
    });
}

export function register(data) {
    return request({
        url: '/login/register',
        method: 'post',
        data: data
    });
}

export function forgotpw(data) {
    return request({
        url: '/login/forgotpw',
        method: 'post',
        data: data
    });
}

export function getCheckUser(){
  return request({
    url: '/user/check_user',
    method: 'get',
  })
}

export function getUserList(data) {
    return request({
        url: '/user/list',
        method: 'post',
        data: data
    });
}

export function getUserInfo(id) {
    return request({
        url: '/user/info/' + id,
        method: 'get',
    });
}

export function addUser(data) {
    return request({
        url: '/user/add',
        method: 'post',
        data: data
    });
}

export function editUser(id, data){
    return request({
        url: '/user/edit/' + id,
        method: 'put',
        data: data
    });
}

export function delUser(data) {
    return request({
        url: '/user/del',
        method: 'post',
        data: data
    });
}