import request from '@/utils/request';

export function getTrendsList(data) {
    return request({
        url: '/trends/list',
        method: 'post',
        data: data
    });
}

export function getTrendsInfo(id) {
    return request({
        url: '/trends/info/' + id,
        method: 'get',
    });
}

export function approveTrends(data){
    return request({
        url: '/trends/approve',
        method: 'post',
        data: data
    });
}

export function delTrends(data) {
    return request({
        url: '/trends/del',
        method: 'post',
        data: data
    });
}
